/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC } from "react";
import { Typography, Divider, Card, CardContent } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import donate from "./assets/paypal-donate-button.png";
import {
    APPLE_APP_STORE_LOGO,
    APPLE_COMPANION_LINK,
    GOOGLE_COMPANION_LINK,
    GOOGLE_PLAY_LOGO,
} from "../../constants";

import StepsToExport from "./components/StepsToExport";
import { A } from "../common";
import { banner, info } from "../../info";
import { useSelector } from "react-redux";
import { Profile, RootState } from "../../reducers";
import GoogleLogo from "./assets/get-it-on-google-play.png";

const useStyles = makeStyles({
    cardStyles: {
        color: "black",
        width: "70vw",
        margin: "auto",
        marginTop: "3rem",
        marginBottom: "3rem",
        maxWidth: 667,
    },
    donate: {
        height: 50,
        display: "block",
    },
    donateImage: {
        height: 50,
        width: "auto",
    },
    appStore: {
        display: "inline-block",
        overflow: "hidden",
        background:
            "url(https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat",
        width: 135,
        height: 40,
        backgroundSize: "contain",
    },
    homeBannerWrapper: {
        width: "100%",
        height: "auto",
        minHeight: "162px",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundColor: info.color,
        backgroundImage: `url(${banner})`,
    },
    downloadAppsHeader: {
        fontSize: 18,
        fontWeight: 500,
    },
    apps: {
        display: "flex",
        justifyContent: "space-around",
    },
    app: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "66px",
    },
    divider: { margin: "15px 0" },
    email: { paddingLeft: 4 },
    androidApp: {
        display: "inline-block",
        overflow: "hidden",
        background: `url(${GoogleLogo}) no-repeat`,
        width: 135,
        height: 40,
        backgroundSize: "contain",
    },
});

const Home: FC = () => {
    const classes = useStyles();
    const profile = useSelector<RootState, Profile>(
        (state) => state.firebase.profile
    );
    return (
        <div className="home">
            <Card className={classes.cardStyles}>
                <div className={classes.homeBannerWrapper} />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Export Data from the Madden Companion App
                    </Typography>
                    <Typography component="div">
                        <StepsToExport />
                        <div>
                            <div className={classes.downloadAppsHeader}>
                                Download the Madden NFL {info.year} Companion
                            </div>
                            <div className={classes.apps}>
                                <div className={classes.app}>
                                    <A
                                        href={APPLE_COMPANION_LINK}
                                        className={classes.appStore}
                                        aria-label="Madden Companion Apple Link"
                                    >
                                        <img
                                            src={APPLE_APP_STORE_LOGO}
                                            alt="Download on the App Store"
                                        />
                                    </A>
                                </div>
                                <div className={classes.app}>
                                    <A
                                        className={classes.androidApp}
                                        href={GOOGLE_COMPANION_LINK}
                                        aria-label="Madden Companion Google Link"
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider className={classes.divider} />
                        <p>
                            Need help? Export failing or data not showing up?
                            <A
                                className={classes.email}
                                href={
                                    profile.username != null ||
                                    profile.email != null
                                        ? `mailto:jbirdwell34@gmail.com?subject=Support Ticket - ${profile.username} - ${profile.email}`
                                        : "mailto:jbirdwell34@gmail.com"
                                }
                                aria-label="Email me for help."
                            >
                                Email Me
                            </A>
                        </p>
                        <p>
                            Donations allow me to keep this going and help with
                            further development.
                            <A
                                className={classes.donate}
                                href="https://www.paypal.me/jbirdwell"
                            >
                                <img
                                    className={classes.donateImage}
                                    src={donate}
                                    alt="PayPal donate"
                                />
                            </A>
                        </p>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};

export default Home;
