export const APPLE_COMPANION_LINK =
    "https://apps.apple.com/us/app/madden-nfl-25-companion/id1422882838";
export const APPLE_APP_STORE_LOGO =
    "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1534809600";
export const GOOGLE_COMPANION_LINK =
    "https://play.google.com/store/apps/details?id=com.ea.gp.madden19companionapp&hl=en_US";
export const GOOGLE_PLAY_LOGO =
    "./components/home/assets/get-it-on-google-play.png";

export const LIST_PATH = "/projects";
export const DETAIL_PATH = ":projectname";
export const ACCOUNT_PATH = "/account";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";

export const ACCOUNT_FORM_NAME = "account";
export const LOGIN_FORM_NAME = "login";
export const SIGNUP_FORM_NAME = "signup";
export const PHONE_FORM_NAME = "phone";
export const RECOVER_CODE_FORM_NAME = "recoverCode";
export const RECOVER_EMAIL_FORM_NAME = "recoverEmail";
export const NEW_TODO_FORM_NAME = "newTodo";

export const formNames = {
    account: ACCOUNT_FORM_NAME,
    signup: SIGNUP_FORM_NAME,
    login: LOGIN_FORM_NAME,
    phone: PHONE_FORM_NAME,
    recoverCode: RECOVER_CODE_FORM_NAME,
    recoverEmail: RECOVER_EMAIL_FORM_NAME,
    newTodo: NEW_TODO_FORM_NAME,
};

export const paths = {
    list: LIST_PATH,
    account: ACCOUNT_PATH,
    detail: DETAIL_PATH,
    login: LOGIN_PATH,
    signup: SIGNUP_PATH,
};

const constants = { ...paths, ...formNames };

export default constants;
